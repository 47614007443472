import { useAuth0 } from '@auth0/auth0-react';
import { 
    IonContent, 
    IonHeader, 
    IonPage, 
    IonTitle, 
    IonToolbar, 
    IonList, 
    IonItem, 
    IonLabel, 
    IonListHeader,
    IonButtons,
    IonBackButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonButton,
    IonInput,
    IonItemDivider,
    IonGrid,
    IonCol,
    IonRow,
    IonTextarea,
    IonFooter,
} from '@ionic/react';
import { Redirect, RouteComponentProps } from 'react-router';
import LoginButton from '../../components/buttons/LoginButton';
import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';

import useSWR from 'swr'
import fetch from 'unfetch'
import { userInfo } from 'os';
import FooterContent from '../../components/FooterContent';
import TPAnimation from '../../components/TPAnimation';

interface LocationDetailPageProps
  extends RouteComponentProps<{
    id: string;
  }> {}


type LocData = {
  name?: string;
  id?: number;
  isTapped?: boolean;
  is404?: boolean;
};







const fetcher = url => fetch(url).then(r => r.json())


const LocationDetail: React.FC<LocationDetailPageProps> = ({match}) => {

  function LogTapIn(){
    setisTapped(true)
    fetch(`${backendURL}/tap/in/`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      account: user.sub,
      location: match.params.id,
    })
  }).then((response) => {return response.json()})
  .then((data) => {
    console.log(data)
    setTapID(data.TapIn);
    setQ1(data.Q1);
    setQ2(data.Q2);
    // setQ3(data.Q3);
  })
  }

  function CheckTapIn(){
    fetch(`${backendURL}/tap/check/`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      account: user.sub,
      location: match.params.id,
    })
  }).then((response) => {return response.json()})
  .then((data) => {
    if(data.TapIn){
      setTapID(data.TapIn);
      setisSubmitted(data.submitted);
      setQ1(data.Q1);
      setQ2(data.Q2);
      // setQ3(data.Q3);
      setisTapped(true)
    }
  })
  }

  function SubmitResponse(){
    fetch(`${backendURL}/tap/submit/`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: TapID,
      Q1R: Q1R,
      Q2R: Q2R,
      // Q3R: Q3R
    })
  }).then((response) => {return response.json()})
  .then((data) => {
    if(data.TapIn){
      setTapID(data.TapIn);
      setisSubmitted(data.submitted);
    }
  })
  }


  function SkipResponse(){
    fetch(`${backendURL}/tap/submit/`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: TapID,
      Q1R: "",
      Q2R: "",
      // Q3R: Q3R
    })
  }).then((response) => {return response.json()})
  .then((data) => {
    if(data.TapIn){
      setTapID(data.TapIn);
      setisSubmitted(data.submitted);
    }
  })
  }
  
  const {loginWithRedirect,logout,user,isLoading,isAuthenticated} = useAuth0();
  const [Q1, setQ1] = useState<string>();
  const [Q2, setQ2] = useState<string>();
  // const [Q3, setQ3] = useState<string>();
  const [Q1R, setQ1R] = useState<string>("");
  const [Q2R, setQ2R] = useState<string>("");
  // const [Q3R, setQ3R] = useState<string>();
  const [isTapped, setisTapped] = useState<boolean>(false);
  const [isSubmitted, setisSubmitted] = useState<boolean>(false);
  const [TapID, setTapID] = useState<string>('');

  const backendURL = process.env.REACT_APP_API_BACKEND_ENDPOINT;

  let locdata: LocData = {};

  const { data, error } = useSWR(`${backendURL}/api/location/?code=${match.params.id}`, fetcher)
  
  if(user){
    CheckTapIn();
  }

  if (error) return (<IonPage>
        <IonContent>

        <h1>An error has occured!</h1>
        </IonContent>
      </IonPage>
  )
  if (!data) return (
    <IonPage>
        <IonContent>

          
        </IonContent>
      </IonPage>

  )

  else if(data){
    //console.log(data);
    if(data.length === 0){
      return(
        <Redirect to="/home" />
      )
    }
    locdata.name = data[0].title

    return (
      <TPAnimation>

      
      <IonPage>
        <IonContent>

        <IonGrid>
            <IonCol>
              <IonRow>
              {!isTapped &&(
              <h1 className='locTitle'>Welcome to {locdata.name}</h1>
              )}

              {isTapped && !isSubmitted &&(
                <>
                  <h1 className='locTitle'>Thanks for tapping-in!</h1>
                  <p>Many thanks for tapping-in. If you are happy to answer two quick questions for {locdata.name}, that would be appreciated, if not please just press skip.</p>
                </>
              
              )}

              {isTapped && isSubmitted &&(
                <>
                  <IonRow>
                  <h1 className='locTitle'>Thank you for your answers.</h1>
                  </IonRow>
                  <p>Your responses allow {locdata.name} to do more. You can see all your Tap-in reposnses on your <a href="/data">My Data page</a>.</p>
                </>
              
              )}
              </IonRow>
            


        
  
        

            
              
  
              {!isLoading && !user &&( 
              <IonCard color={"dark"}>
                <IonCardContent>
                 <LoginButton/>
                 </IonCardContent>
          </IonCard>
              )}
  
              {!isLoading && user && !isTapped &&(
                <>

                <IonCard color={"dark"}>
                  <IonCardContent>
  
                <IonButton
                expand="block" fill="solid" size="large"
                className="btn btn-primary btn-block"
                onClick={() => LogTapIn()}
                >
                  Tap-In Here
                </IonButton>
                </IonCardContent>
          </IonCard>
                </>
                
              )}
  
              {!isLoading && user && isTapped && !isSubmitted &&(
                <>

                <IonCard color={"dark"}>
                 <IonCardContent>

                  <IonCardHeader>
                  <h1>{Q1}</h1>
                  </IonCardHeader>
                
                
                <IonItem color={"dark"}>
                  <IonTextarea autoGrow={true} inputMode="text" value={Q1R} placeholder="Your own words..." onIonChange={e => setQ1R(e.detail.value!)} />
                </IonItem>

                </IonCardContent>
          </IonCard>

          <IonCard color={"dark"}>
                 <IonCardContent>
  
                 <IonCardHeader>
                  <h1>{Q2}</h1>
                  </IonCardHeader>

                <IonItem color={"dark"}>
                  <IonTextarea autoGrow={true} inputMode="text" value={Q2R} placeholder="Your own words..." onIonChange={e => setQ2R(e.detail.value!)} />
                </IonItem>

                </IonCardContent>
          </IonCard>

          {/*
          <IonCard color={"dark"}>
                 <IonCardContent>
  
                <h1>3. {Q3}</h1>
                <IonItem color={"dark"}>
                  <IonTextarea autoGrow={true} inputMode="text" value={Q3R} placeholder="Your own words..." onIonChange={e => setQ3R(e.detail.value!)} />
                </IonItem>

                </IonCardContent>
          </IonCard>
              */}

          <IonCard color={"dark"}>
                 <IonCardContent>
  
                <IonButton
                expand="block" fill="solid" size="large"
                className="btn btn-primary btn-block"
                  onClick={SubmitResponse}
                >
                  Submit answers
                </IonButton>

                </IonCardContent>
          </IonCard>

          <IonCard color={"dark"}>
                 <IonCardContent>

                <IonButton
                expand="block" fill="outline" size="large"
                className="btn btn-danger btn-block"
                  onClick={SubmitResponse}
                >
                  Skip
                </IonButton>

                </IonCardContent>
          </IonCard>
                </>
  
  
              )}

            </IonCol>
        </IonGrid>

        <FooterContent />
        
        </IonContent>
        

              

      
      </IonPage>

      
      </TPAnimation>
    );
  }

  
};

export default LocationDetail;
