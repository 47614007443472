import { IonCol, IonGrid, IonRow } from "@ionic/react";

const ExploreContainer = () => {
  return (
    <>
    <IonGrid>

    
      <IonRow className="fContent">



<IonCol sizeMd='6' sizeSm='6' sizeXs='6'>
    <>
    <p className='footerLink'>
      Commissioned and funded by <br/><a target="_black"href="https://madeinplymouth.co.uk">Made In Plymouth</a>
    </p>
    <p className='footerLink'>
      Designed and developed by <br/><a target="_black"href="https://www.micronomy.co.uk">Micronomy</a>
    </p>         
    </>
  </IonCol>

  <IonCol sizeMd='6' sizeSm='6' sizeXs='6'>

  
  <h1 className='foryou'>For you<br/>
    For us<br/>
    For Plymouth</h1>
  </IonCol>

  </IonRow>
  </IonGrid>


  


    </>   
  );
};

export default ExploreContainer;
