import { Redirect, Route } from 'react-router-dom';
import {
  createAnimation,
  IonApp,
  IonFooter,
  IonIcon,
  IonImg,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle } from 'ionicons/icons';

import DataTab from './components/DataTab';

import Home from './pages/Home';
import MyData from './pages/MyData';

/* AUTH */
import Auth0ProviderWithHistory from './auth0Provider';
import { useAuth0 } from '@auth0/auth0-react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/text.css'
import LocationDetail from './pages/locations/LocationDetail';

/* Connection */
import React, { useEffect, useState } from "react";
import Privacy from './pages/Privacy';
import Lottie from 'react-lottie-player';
import logoJson from './animations/tap-in_loading.json'

import { AnimatePresence } from 'framer-motion'
import Callback from './components/Callback';

setupIonicReact({
  mode: 'md',
});


const App: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const colors = ["#ED2089", "#00A0C6", "#F58020", "#9AC73C"];

  let color = colors[Math.floor(Math.random() * colors.length)];

  document.documentElement.style.setProperty('--ion-color-primary-contrast', color, "important");

  document.documentElement.style.setProperty('--ion-background-color', color, "important");
  document.getElementsByTagName('meta')["theme-color"].content = color;


  if (isLoading) {
    return(<Lottie
      animationData={logoJson}
      play
      loop={false}
     style={{ width: '100vw' }}
  />);
  }
  
  return(
  <IonApp >
    <IonReactRouter >
      <IonTabs>
        <IonRouterOutlet>
          <Auth0ProviderWithHistory>
          <Route exact path="/callback">
            <Callback />
          </Route>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route path="/data">
            <MyData />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/here/:id" component={LocationDetail} />
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          </Auth0ProviderWithHistory>
        </IonRouterOutlet>
        <IonTabBar slot="top" translucent={true}>
        <IonTabButton className='barLogo' tab="home" href="/home"> 
              <IonImg src={"assets/images/Tap-in_Logo_Lock-up-white.svg"} />
          </IonTabButton>
          <IonTabButton tab="home" href="/home"> 
            <IonLabel className="headerLabel">Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab="data" href="/data">
            <IonLabel className="headerLabel">My Data</IonLabel>
          </IonTabButton>
          <IonTabButton tab="privacy" href="/privacy">
            <IonLabel className="headerLabel">Privacy</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>

      
    
    </IonReactRouter>
    
  </IonApp>
  
)};

export default App;
