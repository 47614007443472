import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { IonButton, IonIcon, IonContent } from '@ionic/react';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: window.location.href,
      },
    });
  };

  return (
    <IonButton
    expand="block" fill="solid" size="large"
      className="btn btn-primary btn-block"
      onClick={() => {
        localStorage.setItem("loginURL", window.location.pathname)
        handleLogin()
      }}
    >
      Log-in to Tap-in
    </IonButton>
  );
};

export default LoginButton;