
import React from 'react';

import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

import { useAuth0 } from '@auth0/auth0-react';

const AuthenticationButton = () => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? <><h1>Welcome to Tap-in!</h1><p>To get started, scan in a Tap-in QR code. They can be found right across the city.<br /><br /></p> <p>Your can also help by answering some optional questions about yourself on your <a href="/data">My Data</a> page.</p></> : <LoginButton />;
};

export default AuthenticationButton;