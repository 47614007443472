import React, { ReactNode } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

const variants = {
    hidden: { opacity: 0},
    enter: { opacity: 1},
    exit: { opacity: 0 },
}

type Props = {
    children: ReactNode
}

const TPAnimation = ({ children }: Props) => (
    <>

    <AnimatePresence>

        <motion.main
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
        >
            {children}
        </motion.main>

    </AnimatePresence>
    </>
)

export default TPAnimation