import { IonContent, IonHeader, IonPage, IonTitle, IonButtons, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonFooter, IonImg, IonGrid, IonRow, IonCol } from '@ionic/react';
import { useAuth0 } from '@auth0/auth0-react';
import AuthenticationButton from '../components/buttons/AuthenticationButton';
import FooterContent from '../components/FooterContent';

import Lottie from 'react-lottie-player'
import logoJson from '../animations/tap-in_logo.json'
import { AnimatePresence, motion } from 'framer-motion';
import TPAnimation from '../components/TPAnimation';


const Home: React.FC = () => {
  const {loginWithRedirect,logout,user,isLoading,isAuthenticated} = useAuth0();

  return (
    <TPAnimation>

    
    <IonPage>
      <IonContent>

        

        <IonGrid>
        <IonRow>
          <IonCol sizeMd='6' sizeSm='12' sizeXs='12'>

          <Lottie 
            className='homeLogo'
            animationData={logoJson}
            play
            loop={false}
        />

        </IonCol>
        
        <IonCol sizeMd='6' sizeSm='12' sizeXs='12'>

          <IonCard className='rightCard' color={"dark"}>
          

          <IonCardContent>
          <AuthenticationButton />
      </IonCardContent>
        </IonCard>
        </IonCol>
      </IonRow>

        <IonRow>
          <IonCol>

          <h1>About Tap-in</h1>

          <p>Tap-In Plymouth is a super simple QR code-based way for local people to show their support for all kinds of entertainment, arts, creativity and culture opportunities and options right across the city.</p>

          <p>Twelve key partners, many who are involved with British Art Show 9, are part of the initial pilot which will run from October until December this year.</p> 

          <p>Tap-in Plymouth is based on the science of microinteraction: A small interaction repeated over time builds data and insight, showing support and demand.</p>

          <p>All people need to do to mark the power of their ongoing microinteractions, is quickly register by scanning one of the hundreds of QR codes found online, or in multiple poster sites around the city and tap-in a very small amount of info about themselves.</p>
          
            
          </IonCol>
        </IonRow>

        </IonGrid>

        <FooterContent />
        

        

        

      </IonContent>
    
    </IonPage>
    </TPAnimation>
  );
};

export default Home;
