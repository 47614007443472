import { IonTabBar,
    IonTabButton,
    IonTabs, IonLabel, IonIcon} from '@ionic/react';
    import { ellipse, square, triangle } from 'ionicons/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router';


const Callback = () => {
    const { isAuthenticated, user, isLoading } = useAuth0(); 
    

    let redirect:string = localStorage.getItem("loginURL");
    console.log(redirect);

    /*if(!redirect.startsWith('/here/')){
        redirect = '/data'
    }*/

    //if(!isLoading){
        //if (isAuthenticated){
            return (<><Redirect to={redirect} /></>);
        //}else{
          //  return (<><Redirect to="/data" /></>);
        //}
    //}

    return(<></>);
    
  };
  
  export default Callback;