import { IonContent, IonHeader, IonPage, IonTitle, IonButtons, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonFooter, IonImg, IonGrid, IonRow, IonCol } from '@ionic/react';
import { useAuth0 } from '@auth0/auth0-react';
import AuthenticationButton from '../components/buttons/AuthenticationButton';
import FooterContent from '../components/FooterContent';
import TPAnimation from '../components/TPAnimation';

const Privacy: React.FC = () => {
  const {loginWithRedirect,logout,user,isLoading,isAuthenticated} = useAuth0();

  return (
    <TPAnimation>
    <IonPage>
      <IonContent>

        <IonGrid>
        <IonRow>
          <IonCol>
          <h1>Privacy</h1>

          <p>The Tap-In platform is developed and hosted by Controlled Frenzy Ltd on behalf of Plymouth Culture, its digital platform Made in Plymouth and the project's partners. This means Controlled Frenzy Ltd is responsible for storing any data collected by the project, in accordance with their <a href="https://www.controlledfrenzy.co.uk/privacy">Privacy Policy</a>. No unprocessed Tap-In data is stored by Plymouth Culture or Made in Plymouth.</p>

          <p>Tap-In is designed to collect minimal data as you use it, and all data we collect is strictly anonymised before we process it to provide analytics and insights to our partners. You can use Tap-In without answering any questions, and any responses you give can be deleted from your My Data page at any time - they will then be instantly removed from our system. You can also contact Controlled Frenzy Ltd by emailing <a href="mailto:data@controlledfrenzy.co.uk" >data@controlledfrenzy.co.uk</a></p>

          <p>
          Your data is kept within the Tap-In project's scope and is not resold in any way.
          </p>

          <p>We currently use the following 3rd party services to process data:</p>

          <ul>
            <li>Auth 0 - which provides accounts and login service for Tap-In</li>
            <li>OpenCage - which allows us turn the post code you provide into use information </li>
            <li>Plausible - which provides a way to see how the Tap-In web app is accessed and use </li>
          </ul>

          
            
          </IonCol>
        </IonRow>

        
        

        </IonGrid>

        <FooterContent />

        

      </IonContent>
    
    </IonPage>
    </TPAnimation>
  );
};

export default Privacy;
