import { useAuth0 } from '@auth0/auth0-react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent, 
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonTextarea,
  IonFooter} from '@ionic/react';
import { key } from 'ionicons/icons';
import { useState } from 'react';
import LogoutButton from '../components/buttons/LogoutButton';
import Moment from 'moment';
import TPAnimation from '../components/TPAnimation';
import FooterContent from '../components/FooterContent';
import LoginButton from '../components/buttons/LoginButton';

function formatDate(input:string){
  return Moment(input).calendar();
}


const MyData: React.FC = () => {

  function deleteTap(id:string){
    fetch(`${backendURL}/tap/delete/`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        TapIn: id,
      })
    }).then((response) => {return response.json()})
    .then((data) => {
      GetJourney();
    })
  }

  function GetProfile(){
    fetch(`${backendURL}/tap/profile/get/`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      account: user.sub,
    })
  }).then((response) => {return response.json()})
  .then((data) => {
    setGotProfile(true);
    setAge(data.age);
    setPostcode(data.postcode);
    setAbout(data.about);
    setSubmitted(data.submitted);
  })
  }

  async function GetJourney(){
    fetch(`${backendURL}/tap/journey/`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      account: user.sub,
    })
  }).then((response) => {return response.json()})
  .then((data) => {
    setGotJourney(true);
    setJourney(data.journey)
  })
  }

  function UpdateProfile(){
    fetch(`${backendURL}/tap/profile/update/`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      account: user.sub,
      age: age,
      postcode: postcode,
      about: about
    })
  }).then((response) => {return response.json()})
  .then((data) => {
    setGotProfile(true);
    setAge(data.age);
    setPostcode(data.postcode);
    setAbout(data.about);
    setSubmitted(data.submitted);
    console.log(data)
  })
  }

  const { user, isAuthenticated, isLoading } = useAuth0();
  const backendURL = process.env.REACT_APP_API_BACKEND_ENDPOINT;

  

  const [ageSaved, setAgeSaved] = useState<string>();
  const [postcodeSaved, setPostcodeSaved] = useState<string>();
  const [aboutSaved, setAboutSaved] = useState<string>();

  const [age, setAge] = useState<string>();
  const [postcode, setPostcode] = useState<string>();
  const [about, setAbout] = useState<string>();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [GotProfile, setGotProfile] = useState<boolean>(false);
  const [GotJourney, setGotJourney] = useState<boolean>(false);
  const [journey, setJourney] = useState<{
    TapIn: string;
    location: string;
    Q1: string;
    Q1R: string;
    Q2: string;
    Q2R: string;
    // Q3: string;
    // Q3R: string;
    added: string;
    submitted: boolean;
  }[]>([],);

  if(user !== undefined && isAuthenticated && !isLoading && !GotProfile){
    GetProfile();
  }
  
  if(user !== undefined && isAuthenticated && !isLoading && !GotJourney){
    setGotJourney(true);
    GetJourney();
  }
  

  return (
    <TPAnimation>
    <IonPage>
      <IonContent>
      <IonGrid>
        <IonRow>
          <IonCol>

          <p>Welcome to your data page. Here you can view and edit all answers you’ve given Tap-in.</p>
        
        {user !== undefined && isAuthenticated &&( 
          <>
          
          
          

          <h1>Your Profile</h1>

          <p>Answering these questions helps our partners better understand your preferences.</p>

          
              {!submitted &&( 
                <>
                
                <IonCard color={"dark"}>
                <IonCardContent>
                
                <IonCardHeader><h1>In your own words: Tell us about yourself.</h1></IonCardHeader>
                <IonItem color={"dark"}>
                  <IonTextarea autoGrow={true} inputMode="text" value={about} placeholder="Your own words..." onIonChange={e => setAbout(e.detail.value!)} />
                </IonItem>
                </IonCardContent>
                </IonCard>
  
                <IonCard color={"dark"}>
                <IonCardContent>
                
                <IonCardHeader><h1>How old are you?</h1></IonCardHeader>
                <IonItem color={"dark"}>
                  <IonTextarea autoGrow={true} inputMode="text" value={age} placeholder="Your own words..." onIonChange={e => setAge(e.detail.value!)} />
                </IonItem>
                </IonCardContent>
                </IonCard>

  
                <IonCard color={"dark"}>
                <IonCardContent>
                
                <IonCardHeader><h1>What's your postcode?</h1></IonCardHeader>
                <IonItem color={"dark"}>
                  <IonTextarea autoGrow={true} inputMode="text" value={postcode} placeholder="Your own words..." onIonChange={e => setPostcode(e.detail.value!)} />
                </IonItem>
                </IonCardContent>
                </IonCard>

  
                <IonCard color={"dark"}>
                <IonCardContent>
                <IonButton
                expand="block" fill="solid" size="large"
                className="btn btn-primary btn-block"
                onClick={UpdateProfile}
                >
                  Update Profile
                </IonButton>

                </IonCardContent>
                </IonCard>
                </>
              )}

              {submitted &&( 
                <>
                

              <IonCard color={"dark"}>
                <IonCardContent>

                <IonItem color={"dark"}>
                  <p>In your own words: Tell us about yourself.</p>
                  </IonItem>
                  <IonItem color={"dark"}>
                  <p>{about}</p> 
                </IonItem>
  
                <IonItem color={"dark"}>
                  <p>How old are you?</p>
                  </IonItem>
                  <IonItem color={"dark"}>
                  <p>{age}</p> 
                </IonItem>

                <IonItem color={"dark"}>
                  <p>What's your postcode?</p>
                  </IonItem>
                  <IonItem color={"dark"}>
                  <p>{postcode}</p> 
                </IonItem>

                <IonButton
                expand="block" fill="solid" size="large"
                className="btn btn-primary btn-block"
                onClick={() => setSubmitted(false)}
                >
                  Edit
                </IonButton>
                </IonCardContent>
            </IonCard>
            </>
              )}
            
          <h1>Your Journey</h1>

          {(journey.length === 0) && (
            <>
            <IonCard color={"dark"}>
            <IonCardContent>
          <h1>Welcome to Tap-in!</h1><p>To get started, scan in a Tap-in QR code. They can be found right across the city.</p>
          </IonCardContent>
          </IonCard>
            </>
            
          )}

          {journey.map((tap, index) => {
            return (

              <IonCard key={index} color={"dark"}>
              <IonCardContent>
              <IonItem color={"dark"}>
                  <p>Tapped-in at {tap.location} {formatDate(tap.added)}</p>
                  </IonItem>
              

              
                <IonItem color={"dark"}>
                  <p>{tap.Q1}</p>
                  </IonItem>
                  <IonItem color={"dark"}>
                  <p>{tap.Q1R}</p> 
                </IonItem>

                <IonItem color={"dark"}>
                  <p>{tap.Q2}</p>
                  </IonItem>
                  <IonItem color={"dark"}>
                  <p>{tap.Q2R}</p> 
                </IonItem>

                {/* 
                <h1>{tap.Q3}</h1>
                <IonItem color={"dark"}>
                  <p>{tap.Q3R}</p> 
                </IonItem>
                */}


                <IonButton
                expand="block" fill="outline" size="large"
                  className="btn btn-danger btn-block"
                  onClick={() =>
                    deleteTap(tap.TapIn)
                  }
                >
                  Delete Tap-in
                </IonButton>


              </IonCardContent>
              </IonCard>
            );
          })}

          <h1>Your Account</h1>
          
          <IonCard color={"dark"}>
          <IonCardHeader>
          <p>Your Login Email: {user.email}</p>
          </IonCardHeader>

          <IonCardContent>
                <LogoutButton />
          </IonCardContent>
          </IonCard>

          
          </>
                
            )}

          {!user &&( 
              <IonCard color={"dark"}>
                <IonCardContent>
                 <LoginButton/>
                 </IonCardContent>
          </IonCard>
              )}

            </IonCol>
          </IonRow>
          </IonGrid>


          <FooterContent />
         

          
      </IonContent>

      



    </IonPage>
    </TPAnimation>
  );
};

export default MyData;
