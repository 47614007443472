import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { IonButton, IonIcon, IonContent } from '@ionic/react';

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <IonButton
    expand="block" fill="outline" size="large"
      className="btn btn-danger btn-block"
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      Log Out
    </IonButton>
  );
};

export default LogoutButton;